.banner_services_content {
  @apply relative z-50;
  margin-top: -30px;
  @screen lg {
    margin-top: 0;
  }
  .services-content {
    @screen lg {
      margin-top: -120px;
    }
  }
  .item {
    @apply relative;
    max-height: 120px;
    z-index: 80;
    @screen sm {
      max-height: none;
    }
    * {
      transition: 0.5s ease all;
    }

    .title-content {
      br {
        @apply hidden sm:block;
      }
    }

    .img-content {
      max-height: 120px;
      .simpleParallax {
        @apply h-full;
      }
      @screen sm {
        max-height: none;
      }
    }

    &:hover, &:focus, &:active {
      .img-content {
        img {
          @apply transform rotate-2 filter brightness-105;
        }
      }
      .block-content {
        @apply top-0;
        .icon {
          @apply text-2xl lg:text-3xl xl:text-4xl text-white opacity-100;
        }
      }
    }

    &:first-child {
      .item-layer {
        @apply absolute h-full w-full;
        background: linear-gradient(rgba(220, 85, 56) , rgba(228, 65, 68));
        right: 0;
        top: 0;
        z-index: 10;
      }
    }
    &:nth-child(2) {
      .item-layer {
        @apply absolute h-full w-full;
        background: linear-gradient(rgba(39, 74, 139) , rgba(39, 74, 139));
        right: 0;
        top: 0;
        z-index: 10;
      }
    }

    .img-content {
      @apply relative overflow-hidden;
      img {
        @apply object-cover grayscale;
        max-height: 200px;
        width: 100%;
        @screen md {
          max-height: 210px;
        }
        @screen 2xl {
          max-height: 250px;
        }
      }
    }

    .item-layer {
      @apply absolute mix-blend-multiply h-full w-full;
      background: linear-gradient(rgba(253, 220, 29) , rgba(253, 220, 29));
      right: 0;
      top: 0;
      z-index: 10;
    }

    .block-content {
      @apply top-0 left-1/2;
      transform: translateX(-50%);
      z-index: 50;
      .title {
        @apply text-white relative top-0;
      }
      .icon {
        @apply text-2xl lg:text-3xl xl:text-4xl text-white opacity-100 lg:opacity-0;
      }
      &:hover, &:focus {
        .title {
          @apply -top-[5px] sm:-top-[25px];
        }
      }
    }
  }
}
