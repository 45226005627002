$font-icon: "Font Awesome 6 Pro";

body {
  @apply bg-grey-fulllight text-3xs 2xl:text-2xs text-grey-fulldarker;
  line-height: 1.5;
  @screen md {
    line-height: 1.7;
  }
}

.text-white {
  color: white !important;
}

h1, .title_h1 {
  @apply uppercase tracking-wider text-firstcolor-dark font-bold text-md md:text-lg lg:text-2xl xl:text-3xl inline-block font-title leading-snug pb-3;
  line-height: 1.2;
  &.has-text-align-center {
    @apply text-center;
  }
}

h2, .title_h2 {
  @apply text-secondcolor-medium uppercase tracking-wider font-title font-bold text-sm sm:text-md md:text-lg 2xl:text-2xl leading-tight relative z-10;
  &:before {
    @apply inline-block bg-firstcolor-medium w-[80px] h-0.5 mb-3 mr-3 absolute z-20;
    content: "";
    left: -95px;
    top: 15px;
    @screen 2xl {
      top: 18px;
    }
  }
  &.has-text-align-center {
    @apply text-center;
  }
  &.nobefore {
    &:before {
      @apply hidden;
    }
  }
}

h3, .title_h3 {
  @apply block leading-[1.2] text-2xs sm:text-xs md:text-md lg:text-lg font-inline uppercase;
  &.has-text-align-center {
    @apply text-center;
  }
}



.pretitle {
  @apply font-custom uppercase text-grey-fulldark tracking-wider;
  font-size: 17px;
  &:before {
    @apply inline-block bg-firstcolor-medium mr-3 sm:mr-5;
    content: "";
    height: 16px;
    vertical-align: -3px;
    width: 16px;
    @screen md {
      height: 20px;
      width: 20px;
    }
  }
  @screen md {
    @apply text-sm;
    &:before {
      height: 25px;
      width: 25px;
    }
  }
}

.subtitle {
  @apply font-inline uppercase text-firstcolor-medium tracking-wider text-xs md:text-sm xl:text-md;
  strong {
    @apply font-bold;
  }
}

.txt-deco {
  @apply font-sans font-semibold uppercase text-grey-fulldark tracking-wider;
}

.chapo {
  @apply text-secondcolor-dark font-bold leading-normal text-2xs 2xl:text-xs;
  b, strong {
    @apply text-firstcolor-medium;
  }
}

blockquote {
  @apply font-bold font-custom text-xs sm:text-sm text-grey-fulldark border-l-4 border-firstcolor-light pl-5 my-5;
}

.cat {
  @apply font-custom uppercase bg-firstcolor-light text-white text-4xs md:text-3xs tracking-widest py-1 px-2;
}

.block_cms {
  h1 {
    @extend .title_h2;
  }

  h2 {
    @extend .title_h2;
    //@apply pt-3;
  }

  h3 {
    @extend .title_h3;
    @apply font-custom font-bold text-firstcolor-medium text-xs 2xl:text-sm pb-2;
  }

  ol {
    list-style-type: decimal;
    margin-left: 35px;
  }

  p {
    @apply my-3;
  }

  .txt {
    strong {
      font-weight: 900;
    }
  }

  .btn-styles {
    &.btn-white {
      a {
        span {
          @apply text-firstcolor-medium;
        }
      }
    }
  }


  a {
    @apply text-firstcolor-medium hover:text-firstcolor-medium font-bold border-b-2 border-firstcolor-medium hover:border-firstcolor-medium md:pb-1;
    &.btn {
      @apply text-white hover:text-white py-2;
      span {
        @apply text-white py-2;
      }
      &:hover {
        span {
          @apply text-white;
        }
      }
      &.btn-white, &.btn-arrow {
        @apply text-firstcolor-dark;
        span {
          @apply text-firstcolor-dark;
        }
        &:hover {
          span {
            @apply text-firstcolor-dark;
          }
        }
      }

      &.btn-link {
        @apply text-firstcolor-medium hover:text-firstcolor-medium;
      }

      &.btn-arrow {
        @apply py-0;
      }

      &.btn-square {
        i {
          @apply px-0;
        }
      }
    }

    i {
      @apply pr-3;
    }
  }

  ol {
    li {
      &::marker {
        @apply inline-block text-firstcolor-medium font-bold xl:text-sm;
      }
    }
  }

  ul {
    @apply ml-5 md:ml-12 mb-3;
    li {
      @apply relative pl-5 pb-1;
      &:before {
        @apply absolute block bg-firstcolor-medium rounded-full mt-2 mr-2;
        content: "";
        height: 7px;
        left: 4px;
        top: 5px;
        width: 7px;
      }
      ul {
        @apply mt-2 ml-10;
        li {
          &:before {
            @apply bg-transparent border-2 border-firstcolor-medium pr-0;
          }
        }
      }
    }
  }

  .list {
    ul {
      li {
        &:before {
          display: none;
        }
      }
    }
  }

  .txt {
    p {
      @apply mt-0 mb-5;
    }
  }

  img {
    &.alignleft {
      @screen md {
        float: left;
        margin-right: 15px;
      }
    }

    &.alignright {
      @screen md {
        float: right;
        margin-left: 15px;
      }
    }
  }
}
