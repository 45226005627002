footer {
  .logo {
    max-width: 120px;
    @screen xl {
      max-width: 130px;
    }
    @screen 2xl {
      max-width: 155px;
    }
  }
  @screen lg {
    bottom: 0;
    left: 0;
    position: sticky;
    right: 0;
    top: auto;
    z-index: -2;
  }
  .subtitle {
    @apply font-bold text-2xs md:text-xs;
  }
  .credit {
    img {
      max-width: 30px;
    }
  }
  .menu-footer {
    li {
      @apply pb-2;
      a {
        @apply text-grey-medium hover:text-white;
      }
    }
  }
}
