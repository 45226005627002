// IMPORT FONTS
@import "fonts";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'common/import';
@import 'acf/import';
@import 'partials/import';
@import 'pages/import';

@import '~aos/dist/aos.css'; // Animate On Scroll styles
@import '~mmenu-js/dist/mmenu.css'; //Menu burger
