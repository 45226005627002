input {
  max-width: 100%;
}

input, textarea, select {
  @apply transition-colors duration-200 ease-in-out border-2 outline-none border-white bg-transparent bg-opacity-90 focus:border-white focus:ring-2 focus:bg-opacity-100 focus:ring-white placeholder-grey-fulllight py-2 px-3;
}

select {
  width: 100%;
}

.form-styles {
  label {
    @apply font-bold text-xs pb-1 sm:pb-2;
    line-height: 1.2;
    &.label-check {
      @apply font-semibold text-3xs pb-0;
    }
  }
  input, select, textarea {
    @apply border-gray-400 bg-gray-50 text-2xs py-2;
    min-height: 42px;
    &[type="checkbox"], &[type="radio"] {
      display: inline-block;
      cursor: pointer;
      min-height: auto;
      width: auto;
    }
  }
  select {
    @apply border-0;
  }
  ul {
    @apply ml-3;
    li {
      @apply pl-0;
      &:before {
        @apply hidden;
      }
    }
  }
}
