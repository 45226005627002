.banner_portfolio_content, .page-portfolio {
  .deco-txt {
    z-index: -1;
  }
  .item {
    * {
      transition: 0.5s ease all;
    }
    .img-content {
      @apply relative overflow-hidden;
      z-index: 10;
      img {
        @apply object-cover transform scale-100;
      }

      .txt-content {
        @apply absolute opacity-0 h-full;
        left: 50%;
        top: 0;
        transform: translate(-50%);
        z-index: 20;
        .title {
          @apply px-3;
          line-height: 1.2;
        }
      }
    }

    &:hover, &:active, &:focus {
      @apply cursor-pointer;
      .img-content {
        @apply transform shadow-xl;
        z-index: 15;
        img {
          @apply transform scale-125 rotate-2 filter brightness-50;
        }
        .txt-content {
          @apply opacity-100;
        }
      }
    }
  }
}

.banner_portfolio_content {
  .item {
    .img-content {
      img {
        @apply object-cover transform scale-100;
        aspect-ratio: 1;
        width: 100%;
      }
    }
  }
}
