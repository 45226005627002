.banner_logos_content {
  img {
    @apply ease-out duration-300;
  }
  .swiper {
    @apply px-3 md:px-0;
    padding-bottom: 30px !important;
    @screen md {
      padding-bottom: 70px !important;
    }
    img {
      @apply object-contain;
      max-height: 80px;
      transition: 0.3s ease all;
      @screen md {
        max-height: 120px;
        width: 200px;
      }
      @screen xl {
        max-height: 110px;
      }
    }
    .swiper-wrapper {
      @apply flex items-center;
    }
    .swiper-pagination {
      bottom: 0;
    }
  }
}
