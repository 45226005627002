.header {
  top: 0;
  //transition: 0.35s ease-in-out;
  z-index: 8950;
  @screen lg {
    max-height: 80px;
  }

  .header-container {
    .header-content {
      @apply items-start;
      min-height: 75px;
      .logo-link {
        min-width: 100px;
        .logo {
          @apply pt-3;
          max-height: 60px;
          transition: 0.4s ease all;
          @screen md {
            max-height: 105px;
          }
          &.scroll {
            @apply pt-0 hidden w-0 transition ease-out opacity-0 duration-300;
          }
        }
      }
      .btns-contact {
        .btn-devis {
          @apply hidden md:flex;
        }
        .btn-styles {
          @apply  h-[75px] max-w-[4rem] md:max-w-none;
          min-height: auto;
          .btn-effect {
            @apply h-[75px] w-[75px] md:max-w-[4rem] md:w-[4rem] md:max-w-none md:w-[12rem];
            min-height: auto;
          }
        }
      }
    }
  }

  .nav-primary {
    display: none;

    @screen lg {
      display: block;
    }

    .nav {
      @apply flex xl:justify-end align-middle gap-5 2xl:gap-10 mx-0;
      .menu-item {
        @apply relative inline-flex justify-center items-center py-3 md:py-[21px] xl:px-3 2xl:px-0;
        a, span {
          @apply text-white uppercase text-2xs 2xl:text-xs font-bold font-custom px-2;
        }

        &:after {
          @apply block bg-firstcolor-medium w-0 absolute transition-all;
          bottom: 0;
          content: "";
          height: 4px;
        }

        &:hover,
        &:active,
        &:focus {
          &:after {
            @apply w-full;
          }
        }

        &.active {
          &:after {
            @apply w-full;
          }
        }

        &.menu-accueil {
          @apply lg:hidden;
        }


        //--------- Affichage du sous-menu
        &.menu-item-has-children {
          @apply relative;
          span {
            &:after {
              @apply pl-2;
              content: "\f107";
              font-family: $font-icon;
            }
          }
          &:after {
            @apply hidden;
          }

          &:hover, &:focus, &:active {
            .sub-menu {
              display: block;
              .sub-menu {
                display: none;
                .sub-menu {
                  display: none;
                }
              }
            }
          }

          .sub-menu {
            .menu-item-has-children {
              &:hover, &:focus, &:active {
                .sub-menu {
                  display: block;
                  .sub-menu {
                    display: none;
                  }
                }
              }
            }
          }

          .sub-menu {
            .sub-menu {
              .menu-item-has-children {
                &:hover, &:focus, &:active {
                  .sub-menu {
                    display: block;
                  }
                }
              }
            }
          }

          a {
            &:after {
              @apply inline-block font-light text-white bg-transparent pl-3;
              content: "\f107";
              font-family: $font-icon;
              font-size: 19px;
              top: 17px;
              vertical-align: -2px;
            }
          }
        }
      }

      .sub-menu {
        @apply drop-shadow-lg border-t-4 border-firstcolor-medium;
        display: none;
        min-width: 270px;
        position: absolute;
        top: 72px;
        transition: 0.5s ease all;

        .sub-menu {
          display: none;
          left: 100%;
          top: -5px;
          .menu-item {
            &:first-child {
              @apply pt-1;
            }
            &:last-child {
              @apply pb-1;
            }
          }

          .sub-menu {
            display: none;
          }
        }

        .menu-item {
          @apply block bg-white pl-0 text-grey-fulldark font-semibold border-b border-grey-fulllight py-0;
          transition: 0.5s ease all;

          &:after {
            @apply hidden;
          }

          &:first-child {
            @apply pt-1;
          }

          &:last-child {
            @apply pb-1;
          }

          a {
            @apply block text-grey-fulldark text-3xs px-5 py-3;
            &:after {
              @apply hidden;
            }

            &:before {
              @apply text-firstcolor-medium;
              content: "\f054";
              display: inline-block;
              font-family: $font-icon;
              font-size: 10px;
              padding-right: 10px;
              vertical-align: 2px;
            }
          }

          &:hover {
            @apply bg-grey-fulllight;
            padding-left: 5px;

            a {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  //Styles du header au scroll
  &.second_style {
    @apply bg-secondcolor-dark shadow-sm sm:px-10 md:px-0;
    * {
      transition: 0.1s ease all;
    }

    .header-width {
      @apply xl:mx-0;
    }

    .header-container {
      @apply shadow-none py-0;
      margin-top: 0 !important;
      max-width: none !important;

      .header-content {
        @apply items-center;
        border-radius: 0;
        //min-height: 70px;

        .logo-link {
          .logo {
            @apply hidden;
            &.scroll  {
              @apply block opacity-100 w-full pt-0;
              max-width: 80px;
            }
          }
        }

        .nav-primary {
          .nav {
            @apply mt-0 border-none;
            .menu-item {
              &:after {
                @apply bg-firstcolor-medium;
              }

            }
          }
        }
      }
    }

    .menu-btn {
      margin-top: 0;
    }
  }
}

// Styles menu mobile
.top-menu-content {
  max-width: 100%;
}

.menu-btn {
  @apply relative flex justify-center items-center cursor-pointer mr-5 sm:mr-0;
  height: 35px;
  transition: 0.5s ease all;
  width: 25px;
  .menu-btn-burger {
    @apply bg-firstcolor-medium rounded-full;
    box-shadow: 0 2px 5px rgba(#B60000, .3);
    height: 3px;
    width: 35px;
    transition: 0.5s ease all;

    &:before, &:after {
      @extend .menu-btn-burger;
      @apply block absolute;
      content: "";
    }

    &:before {
      transform: translateY(-11px);
    }

    &:after {
      transform: translateY(11px);
    }
  }

  /* ANIMATION */
  &.open {
    .menu-btn-burger {
      @apply bg-transparent;
      box-shadow: none;
      transform: translateX(-50px);

      &:before {
        @apply bg-firstcolor-medium;
        box-shadow: 0 2px 5px rgba(#E63A3F, .3);
        transform: rotate(45deg) translateX(35px) translateY(-35px) !important;
      }

      &:after {
        @apply bg-firstcolor-medium;
        box-shadow: 0 2px 5px rgba(#E63A3F, .3);
        transform: rotate(-45deg) translateX(35px) translateY(35px) !important;
      }
    }
  }
}


.mm-menu {
  @apply bg-secondcolor-dark;
  .search-bar-content {
    button {
      top: 10px;
    }
  }

  .mm-navbar, .mm-panel {
    @apply bg-secondcolor-dark;
    .mm-navbar__title {
      @extend .subtitle;
      @apply tracking-widest;
    }
  }

  .mm-btn {
    &:after, &:before {
      @apply border-firstcolor-medium;
    }
  }

  .nav, .sub-menu {
    .menu-item {
      &:after {
        @apply border-grey-light border-opacity-20;
        left: 0;
      }

      &:last-child {
        &:after {
          @apply border-none;
        }
      }

      a {
        @apply text-white uppercase font-custom tracking-wider font-bold text-2xs;
      }
    }
  }
}
