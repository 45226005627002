.banner_testimonials_content {
  @apply relative;
  .swiper {
    padding-bottom: 10px !important;
    @screen sm {
      padding-bottom: 40px !important;
    }
    @screen md {
      padding-bottom: 70px !important;
    }
    .icon-content {
      img {
        @apply object-contain lg:object-none;
        max-width: 100px;
      }
    }

    .name {
      @apply font-custom;
    }

    .swiper-slide {
      @apply items-start;
      @screen sm {
        min-height: 320px;
      }
      @screen lg {
        min-height: 250px;
      }
      @screen xl {
        min-height: 240px;
      }
      @screen 2xl {
        min-height: 260px;
      }
    }

    .swiper-pagination {
      bottom: 0;
    }
  }

}
