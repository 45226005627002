.btn-styles {
  @apply inline h-14 md:h-16 w-[12rem] md:w-64 flex justify-center items-center;
  top: 0;
  z-index: 5;
  &.btn-xs {
    @apply px-0 w-[12rem];
    .btn-effect {
      @apply w-[12rem];
      transform: none !important;
    }
  }
  .btn-effect {
    @apply h-14 md:h-16 w-[12rem] md:w-64 bg-firstcolor-medium items-center shadow-2xl cursor-pointer absolute z-10 overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out;
  }
  .btn {
    @apply relative z-40 flex items-center justify-center h-full w-full uppercase tracking-widest font-custom text-center border-none text-white font-semibold z-10;
    z-index: 1000000000000000000000;
  }
  &:hover, &:active, &:focus {
    top: -2px;
    span {
      @apply text-white;
    }
  }
  &.btn-white {
    .btn-effect {
      @apply bg-white;
    }
    .btn {
      @apply text-firstcolor-medium;
    }
  }
  &.btn-arrow {
    @apply flex items-center hover:bg-firstcolor-fulldark;
    &:hover, &:active, &:focus {
      top: 0;
      i {
        right: 3px;
      }
    }
    &.btn-white {
      @apply bg-transparent border-white text-white;
      &:hover, &:active, &:focus {
        color: white !important;
      }
      span {
        @apply text-white;
      }
    }
  }
}

.btn-link {
  @apply relative top-0 hover:-top-1 inline-flex items-center font-custom uppercase tracking-wider font-bold bg-transparent rounded-none text-firstcolor-medium hover:text-firstcolor-medium hover:cursor-pointer transition transition-all duration-300 ease-out;
  box-shadow: none;
  i {
    @apply pb-1;
  }
  &.btn-white {
    @apply text-white;
  }
}


.btn-effect::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 20%;
  background: white;
  /* Centering */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.btn-effect:hover:before {
  animation: anim-in 0.7s forwards ease-out;
}

@keyframes anim-in {
  100% {
    opacity: 0%;
    border-radius: 0;
    width: 600px;
    height: 600px;
  }
  0% {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    opacity: 20%;
  }
}
