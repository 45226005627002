/* Tab content - closed */
.cta-content {
  .item {
    @apply text-white bg-secondcolor-medium;
    .title {
      @apply font-custom font-semibold uppercase text-xs lg:text-sm tracking-wider;
      line-height: 1.3;
    }
    .btn-styles {
      .btn-effect {
        @apply hover:bg-white text-firstcolor-medium;
      }
    }
    &.card {
      @apply relative;
      z-index: 1;

      &:before {
        @apply block absolute bg-firstcolor-medium opacity-0 w-2 h-full;
        content: '';
        left: 0;
        top: 0;
        transition: width 0.5s ease;
        z-index: 5;
      }

      &:hover, &:active, &:focus {
        &:before {
          @apply opacity-100 w-full;
        }

        .title-content, .txt-content, .btn-content {
          @apply relative text-white;
          z-index: 10;
          .title, h2, h3 {
            @apply text-white;
          }
        }

        a {
          @apply text-white border-white;
        }

        .btn-content {
          .btn-styles {
            @extend .btn-white;
          }
        }
      }
    }
  }
}
