.flexible-content {
  background-size: 45%;
  @media (max-width: 1280px) {
    background-size: 40%;
  }
  @media (max-width: 1024px) {
    background-image: none !important;
  }

  .bg-white {
    .txt {
      min-height: 65px;
    }
  }

}

.block_cms {
  h2 {
    @apply pb-0;
  }

  .chapo {
    p {
      @apply my-0;
    }
  }


  .img-content {
    z-index: 5;

    img {
      @apply max-w-full 2xl:max-w-[750px];
      object-fit: cover;
    }

    &.img-full {
      @apply px-0;
    }

    &.img-right {
      &.img-full {
        @apply md:pl-10 xl:pl-20;
      }
    }

    &.img-left {
      &.img-full {
        @apply md:pr-10 xl:pr-20;
      }
    }

    .block-citation {
      @screen lg {
        margin-top: -80px;
        position: relative;
        z-index: 20;
      }
    }
  }

  &.bg-img, &.bg-color {
    .txt-content {
      @apply relative;
      z-index: 30;
    }
  }

  &.bg-img {
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    .img-content {
      z-index: 30;
    }

    .swiper {
      .swiper-pagination {
        .swiper-pagination-bullet {
          @apply bg-white;
        }
      }
    }
  }
}

.section-white {
  p, span, h1, h2, h3, h4, li, .chapo {
    @apply text-white;
  }

  a {
    @apply text-white border-white opacity-90 hover:text-white hover:border-white hover:opacity-100;
    &.btn-white {
      @apply text-firstcolor-dark;
    }
  }
}

// ------- Styles spécifiques - Flexibles Réassurance & Slider
.row-reassurance, .row-slider {
  h2, h3, h4 {
    @apply mb-2 pt-0;
    text-align: inherit;
  }
}

.row-slider {
  display: table;

  .item {
    .img-content {
      @apply overflow-hidden;
      max-height: 305px;

      img {
        @apply ease-in-out duration-300 object-cover;
        @screen lg {
          height: 290px;
        }
        @screen xl {
          height: 305px;
        }
        &.mini-img {
          height: auto;
          object-fit: contain;
          max-height: 70px;
          aspect-ratio: 1/1;
          @screen sm {
            max-height: 95px;
          }
        }
      }
    }

    &:hover {
      .img-content {
        img {
          @apply scale-105;
        }
      }
    }
  }

  .card {
    display: table-cell;
  }
}

.row-reassurance {
  .item {
    &.card {
      @apply relative;
      top: 0;
      transition: 0.3s ease all;

      &:hover {
        top: -5px;
      }
    }

    .img-center {
      .perks-subtitle {
        @apply pt-3;
      }

      .reassurance-img, .reassurance-txt {
        &.top {
          @apply sm:w-full;
        }
      }

      .reassurance-txt {
        &.top {
          &.col-xs-1 {
            .perks-subtitle {
              p {
                @media(max-width: 768px) {
                  text-align: left !important;
                }
              }
            }
          }
        }
      }

      img {
        @apply object-contain;
        max-width: 100%;
        min-height: 70px;
        @screen md {
          max-width: 80px;
        }
        @screen md {
          max-width: 100px;
        }
        @screen xl {
          min-height: auto;
        }
      }
    }

    .img-left {
      .img-content {
        @apply pr-2 sm:pr-3 md:pr-5;
      }

      .perks-subtitle {
        @apply flex items-center;
        min-height: 60px;
        h3 {
          @apply text-2xs lg:text-xs pb-0 mb-0;
        }
        p {
          @media(max-width: 768px) {
            text-align: left !important;
          }
        }
      }
    }
  }
}

.slider-content {
  a {
    @apply border-none;
  }

  img {
    @apply object-contain;
    &.small-img {
      max-height: 90px;
    }
  }

  // Swiper
  .customSwiper {
    padding-bottom: 0 !important;

    .swiper-slide {
      background: none;
    }

    &.swiper3 {
      .swiper-slide {
        //img {
        //  max-height: 250px;
        //  @screen lg {
        //    max-height: 280px;
        //  }
        //  @screen 2xl {
        //    max-height: 335px;
        //  }
        //}
      }
    }

    &.swiper4 {
      .swiper-slide {
        //img {
        //  max-height: 250px;
        //  @screen sm {
        //    max-height: 175px;
        //  }
        //  @screen lg {
        //    max-height: 200px;
        //  }
        //  @screen 2xl {
        //    max-height: 240px;
        //  }
        //}
      }
    }

    &.swiper6 {
      .swiper-slide {
        //img {
        //  max-height: 120px;
        //  @screen sm {
        //    max-height: 130px;
        //  }
        //  @screen lg {
        //    max-height: 140px;
        //  }
        //  &.thumbnail-img {
        //    max-height: 70px;
        //    max-width: 70px;
        //    @screen lg {
        //      max-height: 90px;
        //      max-width: 90px;
        //    }
        //  }
        //}
      }
    }
  }

  .slider-navigation-white {
    .swiper-button-prev, .swiper-button-next {
      &:after {
        @apply text-white;
      }

      &:hover {
        @apply opacity-100;
        &:after {
          @apply text-white opacity-100;
        }
      }
    }

    .swiper-pagination {
      @apply hidden md:block;
      bottom: -10px;

      .swiper-pagination-bullet {
        @apply bg-white opacity-80 hover:opacity-100;
        &.swiper-pagination-bullet-active {
          @apply bg-firstcolor-medium;
        }
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    @apply text-grey-fulldark opacity-40;
    transition: 0.5s ease all;

    &.swiper-button-disabled {
      @apply opacity-0;
    }

    &:after {
      font-size: 20px;
      @screen lg {
        font-size: 25px;
      }
      @screen xl {
        font-size: 30px;
      }
      @screen 2xl {
        font-size: 35px;
      }
    }

    &:hover {
      &:after {
        @apply text-grey-fulldark opacity-100;
      }
    }
  }

  .swiper-button-prev {
    left: -30px;
    @screen md {
      left: -30px;
    }
    @screen xl {
      left: -50px;
    }
    @screen 2xl {
      left: -60px;
    }
    &:hover {
      @apply pr-2;
    }
  }

  .swiper-button-next {
    right: -30px;
    @screen md {
      right: -30px;
    }
    @screen xl {
      right: -50px;
    }
    @screen 2xl {
      right: -60px;
    }
    &:hover {
      @apply pl-2;
    }
  }


  .swiper-pagination {
    position: relative !important;
    @screen md {
      bottom: -20px !important;
    }

    @screen lg {
      bottom: -30px !important;
    }

    .swiper-pagination-bullet {
      height: 12px;
      width: 12px;

      &.swiper-pagination-bullet-active {
        @apply bg-firstcolor-medium;
      }

    }

  }

  &.navigation-white {
    .swiper-button-prev, .swiper-button-next {
      @apply text-white opacity-40;
      transition: 0.5s ease all;

      &:after {
        @apply text-white;
        font-size: 20px;
        @screen lg {
          font-size: 25px;
        }
        @screen xl {
          font-size: 30px;
        }
        @screen 2xl {
          font-size: 35px;
        }
      }

      &:hover {
        &:after {
          @apply text-white opacity-100;
        }
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        @apply bg-white;
        &.swiper-pagination-bullet-active {
          @apply bg-firstcolor-medium;
        }
      }
    }
  }


}


.video {
  @apply mt-5 md:mt-0;
  .txt {
    p, .wp-video  {
      overflow:hidden;
      padding-bottom:56.25%;
      position:relative;
      height:0;
      width: 100% !important;
      .mejs-video {
        width: 100% !important;
      }
    }
  }
}

.video iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
