@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Roboto+Condensed:wght@400;700&display=swap');

@font-face {
  font-family: 'phosphatesolidmedium';
  src: local('phosphatesolid-webfont'), url('../fonts/phosphatesolid-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'phosphateinlineroman';
  src: local('phosphateinline-webfont'), url('../fonts/phosphateinline-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

//Font awesome PRO
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/light/";
@import "@fortawesome/fontawesome-pro/scss/solid/";
@import "@fortawesome/fontawesome-pro/scss/brands/";
