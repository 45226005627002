.single-portfolio {
  .content {
    .block_cms {
      h2, .title_h2 {
        @apply xl:text-xl mb-0;
      }
      .txt {
        @apply pt-0;
      }
    }
  }
}

.item {
  &.realisation {
    .img-content {
      img {
        aspect-ratio: 1;
      }
    }
  }
}


//---------------- Styles des filtres - Portfolio
.filter_controls {
  .terms_realisations {
    @apply text-grey-fulldarker text-4xs sm:text-2xs xl:text-xs uppercase text-center font-custom tracking-widest border-b-4 border-grey-light mb-3 pb-3 px-2 sm:px-5 md:px-8;
    .filter-item {
      @apply uppercase opacity-80 hover:opacity-100 text-secondcolor-dark hover:text-firstcolor-medium;
      &:after {
        @apply hidden;
      }
      &.btn-active {
        @apply relative text-red-medium opacity-100;
        &:after {
          @apply hidden sm:block absolute bg-red-medium w-full h-2;
          content: "";
          bottom: -18px;
          left: 0;
        }
      }
    }

    &.active, &.terms-all {
      .filter-item {
        @apply relative text-red-medium opacity-100;
        &:after {
          @apply hidden sm:block absolute bg-red-medium w-full h-2;
          content: "";
          bottom: -18px;
          left: 0;
        }
      }
    }
  }
}
