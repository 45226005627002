/* Tab content - closed */
.collapse-content {
  @apply bg-none shadow-none;
  .tab {
    @apply bg-white shadow-sm border-b border-gray-200;
    .collapse-click {
      @apply border-l-4 border-transparent pl-2 md:pl-5;
      transition: padding-left 0.5s ease, background-color 0.3s ease;
      &:hover {
        @apply bg-firstcolor-medium;
        .title-content {
          .picto {
            i {
              @apply text-white;
            }
          }
          .title {
            @apply text-white;
          }
        }
        .btn-collaspe {
          &:after {
            @apply text-white border-grey-fulldark;
          }
        }
      }
      .title-content {
        @apply flex items-center;
        .title {
          @apply font-custom uppercase font-bold text-2xs sm:text-xs my-0 pt-0;
          top: 0;
          line-height: 1.2;
          white-space: break-spaces;
          @screen sm {
            line-height: 1.3;
          }
        }
      }
      .btn-collaspe {
        &:after {
          @apply text-firstcolor-dark border-firstcolor-medium;
          float:right;
          right: 0;
          top: 0;
          display: block;
          width: 1.5em;
          height: 1.5em;
          line-height: 1.5;
          font-size: 1.25rem;
          text-align: center;
          -webkit-transition: all .35s;
          -o-transition: all .35s;
          transition: all .35s;
        }
      }
    }
  }
  .tab-content {
    @apply border-l-4 border-firstcolor-medium bg-white hidden;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
  }
}

/* :checked - resize to full height */
.tab input:checked ~ .tab-content {
  max-height: 100%;
}
/* Collapse formatting when open */
.collapse-click.open {
  @apply border-l-4 border-firstcolor-medium bg-secondcolor-fulldark text-white pl-5 md:pl-8;
  .title-content {
    .title {
      @apply text-white;
    }
  }
  &:hover {
    @apply bg-firstcolor-fulldark;
    .title-content {
      .picto {
        i {
          @apply text-white;
        }
      }
      .title {
        @apply text-white;
      }
    }
  }
  .btn-link {
    &:after {
      @apply bg-firstcolor-medium;
    }
  }
}

.collapse-click {
  .btn-collaspe {
    &:after {
      @apply font-light;
      content: "\f107";
      font-family: $font-icon;
      font-size: 30px;
    }
  }
}

.collapse-click.open {
  .btn-collaspe {
    &:after {
      transform: rotate(180deg);
    }
  }
}
