body {
  @apply font-sans selection:bg-firstcolor-medium selection:text-white md:overflow-x-hidden;

  .mm-page {
    @apply bg-firstcolor-fullight;
  }
  .page-header {
    z-index: 5;
  }

  .page-content {
    z-index: 90;
  }

  #main {
    @apply md:py-3 md:py-0;
    z-index: 2;
  }

  &.single-formation #main {
    @apply py-0;
  }

}

.container {
  @screen xl {
    max-width: 1412px;
  }
  @screen 2xl {
    max-width: 1789px;
  }
}

.row {
  margin: 0 -15px;
  @screen xl {
    margin: 0;
  }
}

.card {
  &:hover {
    @apply cursor-pointer;
  }
}

/* width */
::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.8);
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-firstcolor-medium px-1;
  border-radius: 10px;
  border: 1px solid white;
}

.scroll-top-element {
  z-index: 1000;
}

// Gallery
.gallery {
  @apply flex flex-wrap mt-10;
  .gallery-item {
    @apply pb-5 pr-5;
  }
}

.block_cms {
  &.standard {
    .gallery-columns-4 {
      .gallery-item {
        .image {
          img {
            @apply w-full h-full;
            max-height: 55px;
            @screen sm {
              max-height: 140px;
            }
            @screen xl {
              max-height: 205px;
            }
            @screen 2xl {
              max-height: 250px;
            }
          }
        }
      }
    }
    .gallery-columns-6 {
      .gallery-item {
        .image {
          img {
            max-height: 105px;
            @screen lg {
              max-height: 135px;
            }
            @screen 2xl {
              max-height: 175px;
            }
          }
        }
      }
    }
  }
}

.block_cms {
  .gallery-columns-3 {
    .gallery-item {
      @apply w-1/3;
      .image {
        img {
          @apply object-cover;
          height: 120px;
          max-height: 200px;
          @screen md {
            height: 140px;
          }
          @screen md {
            height: 170px;
          }
          @screen xl {
            height: 200px;
          }
        }
      }
    }
  }
  .gallery-columns-4 {
    .gallery-item {
      @apply w-1/4;
      .image {
        img {
          @apply w-full h-full object-cover;
          max-height: 280px;
        }
      }
    }
  }
  .gallery-columns-5 {
    .gallery-item {
      @apply w-1/5;
      .image {
        img {
          @apply object-cover;
          max-height: 220px;
        }
      }
    }
  }
  .gallery-columns-6 {
    .gallery-item {
      @apply w-1/6;
      .image {
        img {
          @apply object-cover;
          max-height: 140px;
        }
      }
    }
  }
  .gallery-item {
    @apply w-1/2;
    .image {
      img {
        @apply object-cover;
        max-height: 125px;
        @screen sm {
          max-height: 250px;
        }
        @screen md {
          max-height: 395px;
        }
        @screen xl {
          max-height: 465px;
        }
        @screen 2xl {
          max-height: 590px;
        }
      }
    }
  }
  img {
    &.aligncenter {
      margin: 0 auto;
    }
  }
}

// Swiper
.swiper {
  height: 100%;
  padding-bottom: 40px !important;
  width: 100%;

  &.mySwiper, &.mySwiperNumbers {
    @screen xl {
      padding-left: 75px !important;
      padding-right: 75px !important;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    @apply text-grey-dark hidden lg:block opacity-40 hover:opacity-100 hover:text-firstcolor-medium;
    z-index: 40;
  }

  .swiper-pagination-bullet {
    height: 12px;
    width: 12px;

    &.swiper-pagination-bullet-active {
      @apply bg-firstcolor-medium;
    }

  }

  &.mySwiper {

    .swiper-slide {
      @apply bg-transparent;
      .item {
        width: 100%;
        .img-content {
          width: 100%;
        }
      }
    }
  }
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bandeau-black-cover {
  @apply absolute h-full w-full;
  background: radial-gradient(rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.2));
  right: 0;
  top: 0;
  z-index: 10;
  @screen md {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) , rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0) 90%);
  }
}

.bandeau-cover {
  @apply absolute h-full w-full;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) , rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.2));
  right: 0;
  top: 0;
  z-index: 10;
}

.scroll-to-bottom {
  height: 70px;
  width: 32px;

  i {
    font-size: 20px;
  }

  a {
    @apply block;
    height: 90px;
  }
}


.no-margin {
  margin: 0 !important;
}


.mySwipertest {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  .swiper-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .swiper-slide {
    @apply rounded-2xl shadow-lg;
    background-position: center;
    background-size: cover;
    //width: 300px;
    //height: 300px;
    img {
      display: block;
      width: 100%;
    }
  }
}

// Breadcrumb
#breadcrumb {
  z-index: 5;
}

#breadcrumbs {
  line-height: 21px;
  @screen sm {
    line-height: auto;
  }
  ul {
    @apply flex-nowrap;
  }
  a {
    @apply font-custom text-4xs sm:text-3xs;
    &:after {
      @apply text-firstcolor-medium pl-1 lg:pl-2 lg:pr-1;
      content: "\f054";
      display: inline-block;
      font-family: $font-icon;
      font-size: 8px;
      @screen sm {
        font-size: 12px;
      }
    }
  }

  span {
    @apply font-custom text-4xs sm:text-3xs pl-1 md:pl-0;
  }

  .breadcrumb_last {
    @apply text-firstcolor-medium font-bold text-4xs sm:text-3xs;
  }

  &.iwp__header-breadcrumb {
    @apply w-full;
    ul {
      display: flex;
      list-style: none;
      overflow-x: auto;
      padding: 10px 16px;
      white-space: nowrap;
      width: 100%;
      -webkit-overflow-scrolling: touch;
      li {
        &:after {
          @apply hidden;
        }
        a {
          @apply text-grey-fulldark normal-case;
        }
        span {
          @apply text-grey-fulldarker normal-case pl-2 md:pl-1;
        }
      }
    }
  }
}


.block_cms {
  &.equipe {
    .gallery-item {
      img {
        @apply w-full h-full;
        aspect-ratio: 1 !important;
        object-fit: cover !important;
        max-height: none !important;
      }
    }
  }

  .video-content {
    .deco-yellow {
      z-index: -10;
    }
  }
  .video-container {
    @apply relative overflow-hidden;
    width: 100%; /* Pour s'assurer qu'il occupe toute la largeur disponible */
    padding-top: 100%; /* Ratio 1:1 pour le format carré */
    position: relative;

    video {
      @apply absolute inset-0 w-full h-full;
      object-fit: cover; /* Pour remplir le conteneur tout en conservant les proportions */
    }
  }

}
