.page-header {
  max-height: 450px;
  @screen sm {
    max-height: 590px;
  }
  @screen lg {
    max-height: 720px;
  }
  @screen xl {
    max-height: 750px;
  }
}

.top-banner {
  @apply relative;
  height: auto;
  //min-height: 450px;
  //@screen sm {
  //  min-height: 550px;
  //}
  //@screen md {
  //  min-height: 645px;
  //}
  //@screen lg {
  //  min-height: 800px;
  //}
  //@screen 2xl {
  //  min-height: 900px;
  //}

  .simpleParallax {
    @apply w-full;
  }

  h1,.title_h1 {
    @apply text-white;
  }
  .banner-content {
    z-index: 50;
  }
  .bandeau-cover {
    @apply h-auto absolute top-0 left-0;
    background: radial-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.2));
    height: 100%;
    min-height: auto;
    width: 100%;
    //@screen lg {
    //  height: 680px;
    //}
    @screen 2xl {
      //background: linear-gradient(to right, rgba(0,0,0,0.2) 60%, rgba(0,0,0,0.6), rgba(0,0,0,0.7));
      //height: 800px;
    }
  }
  &.bandeau-home {
    max-height: 450px;
    @screen sm {
      max-height: 590px;
    }
    @screen lg {
      max-height: 720px;
    }
    @screen xl {
      max-height: 750px;
    }
    img {
      @apply object-cover;
      aspect-ratio: 3/4;
      @screen sm {
        aspect-ratio: 3/2;
      }
    }
  }
  .bandeau-black-cover {
    background: radial-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2) 70%,  rgba(0,0,0,0.0));
  }
  &.bandeau-page {
    @apply bg-firstcolor-light mt-0;
    background-attachment: fixed;
    height: 200px;
    min-height: auto;
    @screen md {
      height: 310px;
      padding-top: 50px;
    }
    @screen xl {
      padding-top: 60px;
    }
    .title_h1 {
      @apply mb-0 pb-0 md:mt-10 text-center;
      padding-bottom: 0 !important;
    }
    img {
      //margin-top: -10%;
    }
    .bandeau-black-cover {
      background: radial-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2) 70%,  rgba(0,0,0,0.0));
    }
  }
}
