.block_cms {
  &.home {
    .img-content {
      //&:after {
      //  @apply block absolute bg-firstcolor-medium rounded-br-3xl rounded-tl-3xl;
      //  bottom: -20px;
      //  content: "";
      //  height: 700px;
      //  right: -20px;
      //  width: 580px;
      //  z-index: -1;
      //  @screen md {
      //    @apply hidden;
      //  }
      //  //@screen lg {
      //  //  @apply block;
      //  //  height: 250px;
      //  //  left: -80px;
      //  //  width: 250px;
      //  //}
      //  @screen xl {
      //    @apply block;
      //    height: 700px;
      //    width: 580px;
      //  }
      //}
    }
  }
}

.img-content {
  .deco-txt {
    @apply absolute z-10;
    right: -20px;
    @screen xl {
      right: -130px;
    }
    @screen 2xl {
      right: -40px;
    }
  }
}

.bloc-cta {
  -webkit-box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.07);
  box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.07);
  &.card {
    &:hover {
      @apply cursor-pointer;
    }
  }
  .title {
    @apply text-firstcolor-medium mb-3;
    font-size: 18px;
  }
  .icon-content {
    svg {
      max-width: 50px;
      @screen lg {
        max-width: 60px;
      }
      @screen xl {
        max-width: none;
      }
    }
  }
}
