//// Messages mail
.wpcf7-validation-errors, .wpcf7-response-output {
  @apply text-3xs flex justify-center m-auto text-center bg-green-light font-semibold bg-opacity-80 p-5 mb-5;
  border: none !important;
}

.wpcf7 form {
  .wpcf7-response-output {
    @apply bg-green-light font-bold lg:max-w-[80%] xl:max-w-[70%] mx-auto;
  }

  &.failed {
    .wpcf7-response-output {
      @apply bg-red-light;
    }
  }
}


div.wpcf7-mail-sent-ok {
  @apply bg-green-light border-0;
}

.wpcf7-list-item-label, .__helper_label_required {
  @apply font-normal normal-case tracking-normal font-sans text-3xs sm:text-2xs pb-0;
  &:before {
    @apply mr-2;
  }

  a {
    @apply text-white hover:text-white opacity-75 hover:opacity-100 border-firstcolor-light;
  }
}

.wpcf7 {
  .invalid {
    .wpcf7-response-output {
      @apply bg-red-light border-red-light p-3 mt-5 border-0;
    }
  }

  .wpcf7-not-valid-tip {
    @apply text-4xs pt-1 text-white;
  }

  label {
    @apply uppercase font-medium font-custom text-3xs md:text-2xs lg:text-xs tracking-wider pb-2;
    .wpcf7-list-item-label {
      @apply font-medium;
    }
  }

  .mention {
    @apply text-3xs;
    br {
      @apply hidden;
    }
  }

}

//label {
//  @apply inline-block font-bold text-3xs md:text-2xs xs:text-xs pb-1;
//}

textarea {
  height: 150px;
  width: 100%;
}

.wpcf7-form-control-wrap {
  width: 100%;

  input, p {
    width: 100%;
  }

  input, textarea, select {
    @apply text-grey-fulldarker transition-colors duration-200 ease-in-out border border-white outline-none bg-white bg-opacity-90 focus:border-firstcolor-light focus:ring-2 focus:bg-opacity-100 focus:ring-firstcolor-fulldark placeholder-gray-500;
  }

  .wpcf7-acceptance {
    .wpcf7-list-item {
      @apply ml-0;
      .wpcf7-list-item-label {
        vertical-align: -2px;

        a {
          @apply text-white;
        }
      }
    }

    label {
      @apply inline-block;
    }

    input {
      @apply rounded;
      width: 16px;
    }
  }
}

//--- Style du bouton de validation

.btn-form-content {
  @apply pb-0;
  input {
    max-width: 100%;

    &.wpcf7-submit {
      @extend .btn;
      @apply uppercase tracking-widest font-custom text-center border-firstcolor-medium focus:border-firstcolor-medium text-white font-semibold py-2 lg:py-3 px-5 lg:px-10 relative border-none bg-firstcolor-medium text-white hover:bg-white hover:text-firstcolor-medium mb-5;
      top: 0;
      transition: 0.5s ease all;

      &:hover, &:focus, &:active {
        cursor: pointer;
        top: -2px;
      }
    }
  }
}

.wpcf7-spinner {
  @apply absolute;
  display: block;
  right: 0;
  margin: 0 auto;
}

input {
  max-width: 100%;

  &.wpcf7-submit {
    @extend .btn;
    @apply relative border-none bg-grey-fulldark text-white hover:bg-grey-fulldarker hover:text-white mb-5;
    top: 0;
    transition: 0.5s ease all;

    &:hover, &:focus, &:active {
      cursor: pointer;
      top: -2px;
    }
  }
}

.line_form {
  @apply md:px-5;
  @screen lg {
    width: 50%;
  }

  input {
    &.wpcf7-submit {
      @apply text-white hover:text-firstcolor-medium;
    }
  }

  &.line_radio {
    @apply block pb-0;
    width: 100%;

    .wpcf7-radio {
      @apply flex;
    }

    .wpcf7-list-item {
      @apply flex w-1/2 sm:w-6/12 md:w-3/12;
      label {
        @apply flex items-center;
        input {
          width: 18px;
          height: 18px;
          border-radius: 100%;
        }
      }
    }

    .wpcf7-list-item-label {
      @apply normal-case tracking-normal;
    }
  }

  label {
    @apply text-white;
    sup {
      @apply text-red-medium;
    }
  }

  input, textarea, select {
    @apply text-grey-fulldarker text-xs;
  }

  select {
    @apply text-grey-fulldarker text-xs;
  }

  p {
    margin: 0 !important;
  }

  &.w-full {
    width: 100%;
  }


  .wpcf7-form-control-wrap {
    input {
      width: 100%;
    }

    .wpcf7-acceptance {
      input {
        @apply rounded;
        width: 16px;
      }

      label {
        @apply pb-0;
      }
    }
  }

  .checkbox {
    .wpcf7-list-item {
      @apply ml-0;
      label {
        @apply pb-0;
      }
    }
  }

  .wpcf7-form-control-wrap {
    input {
      width: 100%;
    }

    .wpcf7-acceptance {
      input {
        @apply rounded;
        width: 16px;
      }

      label {
        @apply pb-0;
      }
    }
  }

  input[type="checkbox"] {
    padding: 0;
    border-radius: 4px;
  }

  input[type="checkbox"]:checked {
    @apply bg-firstcolor-medium;
  }

  input[type="radio"]:checked {
    @apply bg-firstcolor-medium;
  }

}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -border-radius: 4px;
  height: 23px !important;
  vertical-align: -5px;
  width: 23px !important;
}

[type='checkbox']:checked {
  @apply bg-firstcolor-medium;
  border-color: transparent;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='checkbox']:checked{
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.wpcf7-spinner {
  @apply absolute md:static;
  display: block;
  margin: 0 auto;
}


